import styled from 'styled-components';

export const BodyMiddle = styled.div`
  padding: 80px 110px;
  background: rgb(237, 237, 237);
  background: -moz-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  blockquote {
    background: #fff;
  }
  @media (max-width: 1199px) {
    padding: 50px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;
export const ImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  margin: 0 0 35px 0;
  height: auto;
  width: auto;
  float: right;
  margin-left: 75px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;
export const Body = styled.div`
  padding: 80px 110px;

  @media (max-width: 1200px) {
    padding: 50px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;
